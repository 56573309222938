<!--
 * @Author: 卢林
 * @Date: 2020-03-02 14:05:13
 * @LastEditTime: 2020-03-02 16:49:04
 * @LastEditors: Please set LastEditors
 * @Description: 错误页面
 * @FilePath: /vue-base/vue-base/src/views/PageNotFound.vue
 -->

<template>
  <div>
    <h2>错误页面</h2>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
